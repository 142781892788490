import { render, staticRenderFns } from "./menu-item-panel.vue?vue&type=template&id=1836e4e8&scoped=true&"
import script from "./menu-item-panel.vue?vue&type=script&lang=js&"
export * from "./menu-item-panel.vue?vue&type=script&lang=js&"
import style0 from "./menu-item-panel.vue?vue&type=style&index=0&id=1836e4e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1836e4e8",
  null
  
)

export default component.exports